<template>
  <BCard>
    <div class="d-flex text-black text-2xl font-semibold mb-2">
      <span>User</span>
      <BButton
        v-b-modal.modalDetail
        variant="primary"
        class="ml-auto px-2"
      >
        <span class="flex items-center gap-[14px]"><span
          class="k-user-add h-100 font-bold text-8"
          style="font-weight: 500;"
        /> Undang</span>
      </BButton>
    </div>

    <BCol
      md="12"
      class="d-flex gap-10 w-100 p-0 my-[24px]"
    >
      <div class="d-flex rounded-lg align-items-center border search-bar">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari email, username"
          class="border-0"
          @input="handleSearch()"
        />
      </div>
      <BDropdown
        id="dropdown-1"
        class="filter"
        no-caret
        variant="primary"
        toggle-class="text-decoration-none toggle-custom button-primary rounded-lg py-0 px-1"
      >
        <h5 style="padding-left: 21px;">Filter</h5>
        <template #button-content>
          <b-img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
            class="height-[20px]"
          />
        </template>
        <BDropdown
          id="dropdown-2"
          class="w-100"
          toggle-class="text-left"
          variant="none"
          left
          no-caret
          dropright
        >
          <template #button-content>
            <span class="hover-salmon text-6">Role</span>
          </template>
          <BDropdownForm style="width: 200px">
            <BFormCheckbox
              v-for="(role, index) in roles"
              :key="index"
              v-model="filterSelected"
              :name="`checkbox-${index}`"
              class="text-6 mb-1"
              :value="role.id"
              @change="getListData()"
            >
              <span class="break-all max-w-[164px]">{{ role.role_access_name }}</span>
            </BFormCheckbox>
          </BDropdownForm>
        </BDropdown>
        <h5
          class="pb-0 pt-1 font-bold text-danger w-100 cursor-pointer"
          style="padding-left: 21px;"
          @click="resetFilter()"
        >
          Reset filter
        </h5>
      </BDropdown>
    </BCol>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 288px); overflow-y: scroll"
        class="mt-[24px]"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                @click="openEdit(data.item)"
              >
                <span
                  class="k-edit h-100 font-semibold text-12"
                  style="color: #FBA63C; font-weight: 500;"
                />
              </BButton>
              <BButton
                v-b-modal.modalDelete
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                @click="idItem = data.item.id"
                @deleted="idItem = 0"
              >
                <span
                  class="k-trash h-100 font-bold text-12"
                  style="color: #E31A1A; font-weight: 500;"
                />
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
    <ModalDetail
      :roles="roles"
      :email-edit="emailEdit"
      :is-edit="isEdit"
      :id-edit="idEdit"
      :role-id-edit="roleIdEdit"
      @hidden="getListData(), emailEdit = '', isEdit = false, idEdit = 0, roleIdEdit = null"
    />
    <ModalDelete
      :id-item="idItem"
      @deleted="getListData()"
    />
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { tableField } from './config'
import ModalDetail from './ModalDetail.vue'
import ModalDelete from './ModalDelete.vue'

export default {
  components: {
    ModalDelete,
    ModalDetail,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      fields: tableField,
      alertError,
      alertSuccess,
      filterSelected: [],
      roles: [],
      keyword: '',
      idItem: 0,
      emailEdit: '',
      isEdit: false,
      idEdit: 0,
      roleIdEdit: null,
    }
  },
  computed: {
    roleId() {
      return this.filterSelected.join(',')
    },
  },
  mounted() {
    this.getListData()
    this.getListRole()

    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 5
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${this.keyword}&role_id=${this.roleId}`
      const url = `v1/user_access?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}`
        const url = `v1/user_access?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async getListRole() {
      this.loading = true
      this.offset = 0
      const url = 'v1/role_access?limit=100'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.roles = data
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    resetFilter() {
      this.filterSelected = []

      this.getListData()
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    openEdit(data) {
      this.isEdit = true
      this.idEdit = data.id
      this.emailEdit = data.email
      this.roleIdEdit = this.roles.filter(e => e.id === data.role_access_id)
      this.$bvModal.show('modalDetail')
    },
  },
}
</script>
