import { DAY_MONTH_YEAR } from '@/libs/filterDate'

// eslint-disable-next-line import/prefer-default-export
export const tableField = [
  {
    key: 'email',
    label: 'Email',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'username',
    label: 'Username',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'role_access_name',
    label: 'Role',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'action',
    label: 'Atur',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      textAlign: 'center',
    },
    tdClass: 'text-black text-center',
  },
]
