<template>
  <BModal
    id="modalDetail"
    ref="modalDetail"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
    @shown="show"
    @hidden="hiddenModal"
  >
    <validation-observer
      ref="formRules"
      v-slot="{ invalid }"
    >
      <div class="text-left my-1 d-flex flex-column">
        <div class="font-semibold text-10 text-black mb-1">
          {{ isEdit ? 'Ubah Role' : 'Undang User' }}
        </div>
        <b-col
          v-if="!isEdit"
          md="12"
          class="p-0"
        >
          <b-form-group
            label="Email"
            label-class="text-[14px] font-normal text-black"
          >
            <validation-provider
              name="Email"
            >
              <VSelect
                :value="isEdit ? emailEdit : null"
                :disabled="isEdit ? true : false"
                :clearable="isEdit ? false : true"
                label="email"
                :reduce="option => option.email"
                :options="emailResources"
                placeholder="Tambah Email untuk diundang"
                :selectable="option => !emailSelected.includes(option.email)"
                @search="(search) => search.length > 0 ? onSearch(search) : ''"
                @option:selected="selectEmail($event)"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-else
          md="12"
          class="p-0"
        >
          <b-form-group
            label="Email"
            label-class="text-[14px] font-normal text-black"
          >
            <div class="border px-[12px] py-[8px] w-full rounded bg-[#f8f8f8]">
              {{ emailEdit }}
            </div>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isEdit"
          md="12"
          class="p-0"
          :class="emailSelected ? 'mb-2' : ''"
        >
          <div class="d-flex flex-wrap gap-5">
            <div
              v-for="(e, i) in emailSelected"
              :key="i"
              class="flex items-center px-1 py-[5px] gap-1 rounded-full bg-[#F8F8F8] text-black"
            >
              <span>{{ e }}</span>
              <span
                class="k-close text-[25px] cursor-pointer"
                @click="emailSelected.splice(i, 1)"
              />
            </div>
          </div>
        </b-col>
        <b-col
          md="12"
          class="p-0"
        >
          <b-form-group
            label="Pilih Role"
            label-class="text-[14px] font-normal text-black"
          >
            <validation-provider
              name="Pilih role"
              rules="required"
            >
              <VSelect
                v-model="roleId"
                label="role_access_name"
                :reduce="option => option.id"
                :options="roles"
                placeholder="Berikan Role"
                @input="isEdit ? getDetailData() : null"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <div
          v-if="isEdit"
          class="bg-[#F8F8F8] rounded-t-xl p-2 font-bold"
        >
          Akses role "{{ roleName }}"
        </div>
        <div
          v-if="isEdit"
          class="custom-scroll overflow-y-auto h-[calc(100vh_-_450px)]"
        >
          <div
            v-for="(menu, i) in menus"
            :key="i"
          >
            <div
              v-b-toggle="`menu-${i}`"
              class="d-flex w-100 items-center py-[24px]"
            >
              <span
                class="k-arrow-down-1"
                :class="menu.sub_menu.length > 0 ? '' : 'text-zinc-300'"
              />
              <span class="d-flex ml-[12px] font-bold">{{ menu.name }}</span>
              <BFormCheckbox
                v-model="menuSelected"
                :value="menu.id"
                class="ml-auto"
                disabled
              />
            </div>
            <b-collapse
              v-if="menu.sub_menu.length > 0"
              :id="`menu-${i}`"
              visible
            >
              <div
                v-for="(child, idx) in menu.sub_menu"
                :key="idx + 'a'"
                class="d-flex w-100 items-center py-[24px]"
              >
                <span class="border-l h-[20px] w-[15px]" />
                <span class="d-flex ml-[12px]">{{ child.name }}</span>
                <BFormCheckbox
                  v-model="menuSelected"
                  :value="child.id"
                  class="ml-auto"
                  disabled
                />
              </div>
            </b-collapse>
          </div>
        </div>
        <div class="d-flex gap-12 justify-center mt-5">
          <button
            size="md"
            variant="outline-primary"
            class="w-100 button-secondary"
            @click="$bvModal.hide('modalDetail')"
          >
            Batal
          </button>
          <button
            :disabled="invalid || loadingSubmit || (isEdit ? false : !emailSelected.length)"
            class="w-100 button-primary"
            @click="submit"
          >
            {{ isEdit ? "Simpan" : "Undang User" }}
          </button>
        </div>
      </div>
    </validation-observer>
  </BModal>
</template>

<script>
import VSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import { alertError, alertSuccess } from '@toast'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VSelect,
  },
  props: {
    roles: {
      type: Array,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    idEdit: {
      type: Number,
      default: 0,
    },
    emailEdit: {
      type: String,
      default: '',
    },
    roleIdEdit: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,

      loading: false,
      loadingSubmit: false,
      name: '',
      email: '',
      menus: [],
      menuSelected: [],
      emailResources: [],
      emailSelected: [],
      listPartners: [],
      submitErrors: {},
      roleId: null,
      roleName: null,
      alertError,
      alertSuccess,
    }
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      if (!this.isEdit) {
        const data = {
          role_access_id: this.roleId,
          user_email: this.emailSelected,
        }

        const url = 'v1/user_access/store'
        await komtimAxiosIns
          .post(url, data)
          .then(res => {
            const text = 'Berhasil menambahkan data'

            alertSuccess(text)
            this.$bvModal.hide('modalDetail')
          })
          .catch(error => {
            this.loadingSubmit = false

            if (error.response.status === 500) this.$toast_error({ message: 'Internal Server error' })
            else this.alertError(error)
          })
      } else {
        const data = {
          role_access_id: this.roleId,
        }

        const url = `v1/user_access/${this.idEdit}/update`
        await komtimAxiosIns
          .put(url, data)
          .then(res => {
            const text = 'Berhasil menambahkan data'

            alertSuccess(text)
            this.$bvModal.hide('modalDetail')
          })
          .catch(error => {
            this.loadingSubmit = false

            if (error.response.status === 500) this.$toast_error({ message: 'Internal Server error' })
            else this.alertError(error)
          })
      }
    },
    show() {
      if (this.isEdit) {
        const [role] = this.roleIdEdit
        this.roleId = role
        this.roleName = role.role_access_name
        this.getDetailData(role.id)
      }
      this.getListData()
    },
    hiddenModal() {
      this.emailSelected = []
      this.roleId = 0
      this.$emit('hidden')
      this.loadingSubmit = false
      this.emailResources = []
    },
    async getListEmail(keyword) {
      const params = `&limit=20&offset=0&keyword=${keyword}`
      const url = `v1/staffs/user_access/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          if (data) this.emailResources = data
          else this.emailResources = []
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchEmail: _.debounce((search, it) => {
      it.getListEmail(search)
    }, 500),
    onSearch(search) {
      this.searchEmail(search, this)
    },
    selectEmail(val) {
      this.emailSelected = [...this.emailSelected, val.email]
    },
    async getListData() {
      const url = 'v1/menu_access'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.menus = data
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getDetailData(id) {
      const url = `v1/role_access/${id || this.roleId}/detail`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.roleName = data.role_access_name
          this.menuSelected = data.menu_access.map(e => e.id)

          this.$bvModal.show('modalDetail')
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
  },
}
</script>
